.text {
  font-family: "Courier New", Courier, monospace;
}
.component {
  text-align: center;
}
/* @media screen and (min-width: 1000px) {
  .header { */
/* display: flex; */
/* background-color: red;
  }
} */
/* .header {
  background-image: url("../../img/rockywall_@2X.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; /* Resize the background image to cover 
} */
.darkoverlay {
  /* background-color: rgba(0, 0, 0, 0.7); */
}

.logo {
  animation: appLogoSpin infinite 20s linear;

  pointer-events: none;
  opacity: 0.3;
  max-width: 250px;
  max-height: 250px;
}

.logoBox {
}
@keyframes appLogoSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.typeWriter {
  min-height: 12vh;
}
