/* @media screen and (min-width: 1500px) {
  .App {
  
    background-color: red;
  }
} */

.App {
  background-image: url("./img/rockywall_@2X.png");
  /* background-position: center; */
  background-repeat: repeat;
  /* background-size: cover; */
}

p {
  color: black;
}

/* body {
  margin: 0;
  background-color: rgb(255, 255, 255);
  height: 100%;
} */

.appHeader {
  /* 
  ONLY THING THAT MATTERS BG COLOR
  Dark overlay 
  */
  /* background-color: rgba(160, 151, 151, 0.5); */
  /* min-height: 100vh; */
  /* display: flex; */
  /* flex-direction: column; */

  /* font-size: calc(11px + 2vmin); */
}

.appLink {
  color: #09d3ac;
}
